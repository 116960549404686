<template>
    <div class="modal-profile-item-selector items-start">
        <div class="back-button-wrapper" @click="onClickClose">
            <i class="back-button material-icons">{{ options.icon || 'close' }} </i>
        </div>
        <div class="top">
            <div class="title" v-html="title" />
            <div v-if="showDesc" class="desc" v-html="desc" />
        </div>
        <div v-if="showSearch && options.model !== 'region'" class="input-wrapper items-center">
            <i class="material-icons i-search">search</i>
            <input
                ref="searchValue"
                v-model="searchValue"
                @keydown="onKeydown"
                :placeholder="$translate(`PLACEHOLDER_SEARCH_${options.model.toUpperCase()}`)"
            />
            <i v-if="searchValue" @click="searchValue = ''" class="material-icons">cancel</i>
        </div>
        <div class="no-search-result m-l-20 flex-wrap" v-if="noResult">
            <i class="material-icons-outlined c-danger m-r-4">info</i>
            <span v-html="$translate('NO_SEARCH_RESULT')" />
        </div>
        <ul class="result flex-fill" :class="{ 'grid-col': displayGrid }">
            <li
                @click="onClickItem(item)"
                class="item flex-row items-center flex-between"
                :class="{ selected: item.$$selected }"
                :key="item.id"
                v-for="item in profileItems"
            >
                <div v-html="$translate(getVal(item))" />
                <i class="material-icons i-right">chevron_right</i>
            </li>
        </ul>
    </div>
</template>

<script>
import debounce from '@/modules/debounce'
import profileService from '@/services/profile'

export default {
    name: 'ModalProfileItemSelector',
    props: ['options'],
    data: () => ({
        profileItems: [],
        showSearch: true,
        searchValue: '',
    }),
    watch: {
        searchValue(newVal) {
            if (!newVal) this.search()
        },
    },
    computed: {
        userInputAllowed() {
            return ['job', 'jobType', 'company', 'university', 'major'].indexOf(this.options.model) !== -1
        },
        noResult() {
            return this.showSearch && this.searchValue && !(this.profileItems[0] || {}).id
        },
        endpoint() {
            return this.$case.pluralize(this.options.model)
        },
        params() {
            let params = { name: this.searchValue }
            if (this.options.model === 'company') params.jobCategoryId = this.options.profile.job_category.id
            if (this.options.model === 'university') params.schoolTypeId = this.options.profile.school_type.id
            if (this.options.model === 'station') params.region = this.options.profile.state.name
            if (this.options.model === 'region') {
                params = { name: this.options.profile.state.name, district: this.searchValue }
            }

            return params
        },
        title() {
            if (this.options.model === 'university' || this.options.model === 'company') {
                return this.$translate(this.$case.toConst(`${this.options.model}_input_title`))
            }

            if (this.options.model === 'smoking') {
                return this.$translate(this.$case.toConst(this.options.model)) + ' 여부'
            }

            return this.$translate(this.$case.toConst(this.options.model))
        },
        displayGrid() {
            return ['kind', 'mbti', 'pet', 'shape', 'religion', 'car'].indexOf(this.options.model) > -1
        },
        showDesc() {
            return ['kind', 'mbti', 'car'].indexOf(this.options.model) > -1
        },
        desc() {
            if (!this.showDesc) return

            if (this.options.model === 'kind') {
                return '<span class="f-bold c-primary">2가지</span>를 선택해주세요'
            }

            if (this.options.model === 'mbti') {
                return '<span class="f-bold c-primary">성격유형(MBTI)</span>를 선택해주세요'
            }

            if (this.options.model === 'car') {
                return '<span class="f-bold c-primary">미보유</span>를 선택한 경우 프로필에 노출되지 않습니다'
            }

            return ''
        },
    },
    mounted() {
        this.showSearch = ['region', 'station'].indexOf(this.options.model) !== -1 || this.userInputAllowed

        if (this.options.model === 'smoking') {
            this.profileItems = [{ name: 'NON_SMOKING' }, { name: 'SMOKING' }]
            return
        }

        if (this.options.model === 'gender') {
            this.profileItems = [{ name: 'FEMALE' }, { name: 'MALE' }]
            return
        }

        if (this.options.model === 'car') {
            this.profileItems = [{ name: '보유' }, { name: '미보유' }]
            return
        }

        if (this.options.model === 'mbti') {
            this.profileItems = [...this.$store.getters.mbti]

            return
        }

        if (this.options.model === 'pet') {
            this.profileItems = [...this.$store.getters.pet]

            return
        }

        if (!this.showSearch) {
            this.profileItems = this.$store.getters[this.$case.pluralize(this.options.model)]
            // 학력: 대학교 재학 이상일 때 학교구분: 고등학교 졸업 삭제
            if (this.options.model === 'schoolType' && this.options.profile.school.id !== 5) {
                const temp = this.profileItems.filter(item => {
                    if (item.id !== 14) return item
                })
                this.profileItems = temp
            }

            // 직장구분: 대학생/대학원생 삭제
            if (this.options.model === 'jobCategory') {
                const temp = this.profileItems.filter(item => {
                    if (item.id !== 1) return item
                })
                this.profileItems = temp
            }

            if (this.options.model === 'shape' || this.options.model === 'kind') {
                const gender = this.options.profile.gender || this.$store.getters.me.gender

                if (typeof gender === 'number') {
                    this.profileItems = this.profileItems.filter(item => item.gender === gender)
                } else {
                    this.profileItems = this.profileItems.filter(
                        item => item.gender === (gender.name === 'MALE' ? 0 : 1),
                    )
                }
            }
            return
        }

        this.search()
    },
    methods: {
        search: debounce(async function () {
            try {
                this.profileItems = await profileService[this.endpoint](this.params)

                this.sortRegionAndStation() // 지역, 지하철역 오름차순 정렬

                if (this.profileItems.length === 0 && this.userInputAllowed) {
                    this.profileItems = [{ name: this.searchValue }]
                }

                // 직업 에서도 대학생 삭제
                if (this.options.model === 'job') {
                    const temp = this.profileItems.filter(item => {
                        if (item.id !== 1) return item
                    })
                    this.profileItems = temp
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        }, 200),
        onKeydown() {
            setTimeout(() => {
                this.searchValue = this.$refs.searchValue.value
                this.search(this.searchValue)
            })
        },
        getVal(o) {
            if (this.userInputAllowed && this.noResult) {
                return `'${this.searchValue}'로 입력하기`
            }

            return this.options.model === 'region' ? o.district : o.name
        },
        onClickItem(item) {
            if (this.options.model === 'pet') {
                item.name === '키우지않아요'
                    ? this.profileItems.forEach(o => this.$set(o, '$$selected', false))
                    : this.$set(
                          this.profileItems.find(o => o.name === '키우지않아요'),
                          '$$selected',
                          false,
                      )
            }

            this.$set(item, '$$selected', !item.$$selected)

            setTimeout(() => {
                if (this.options.model === 'kind') {
                    const selected = this.profileItems.filter(o => o.$$selected)
                    if (selected.length < 2) return

                    this.$emit(
                        'close',
                        this.profileItems.filter(o => o.$$selected),
                    )
                } else if (this.options.model !== 'pet') {
                    this.$emit('close', item)
                }

                if (this.options.model !== 'pet') {
                    this.profileItems.forEach(o => this.$set(o, '$$selected', false))
                }
            }, 200)
        },
        sortRegionAndStation() {
            if (this.options.model === 'region') {
                this.profileItems = this.profileItems.sort(function (a, b) {
                    return a.district < b.district ? -1 : a.district > b.district ? 1 : 0
                })
            }

            if (this.options.model === 'station') {
                this.profileItems = this.profileItems.sort(function (a, b) {
                    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                })
            }
        },
        onClickClose() {
            if (this.options.model === 'pet') {
                this.$emit(
                    'close',
                    this.profileItems.filter(o => o.$$selected),
                )
                this.profileItems.forEach(o => this.$set(o, '$$selected', false))
            } else {
                this.$emit('close')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-profile-item-selector {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.2px;
    color: $grey-08;

    @include modal-fullscreen;
    @include f-regular;

    .back-button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 14px $header-left-padding 20px;
    }

    .back-button {
        font-size: 24px;
        color: $grey-08;
        margin: 0;
    }

    .top {
        padding: 0 20px;
        margin-bottom: 40px;

        .title {
            font-size: 24px;
            letter-spacing: -0.4px;
            color: black;

            @include f-medium;
        }

        .desc {
            margin-top: 12px;
            font-size: 16px;
            color: $grey-09;
        }
    }

    .result {
        width: 100%;
        overflow-y: auto;
    }

    .input-wrapper {
        display: flex;
        flex-direction: row;
        width: calc(100% - 40px);
        height: 52px;
        border-radius: 8px;
        border: 1px solid $grey-02;
        padding: 12px 16px;
        margin: 0 20px 12px;

        .material-icons {
            font-size: $icon-size;
            color: $grey-05;
            margin: 0;
        }

        .i-search {
            margin-right: 16px;
            color: $grey-08;
        }

        input {
            font-size: 15px;
            margin: 0;
            padding: 0;
            color: black;
        }

        input::placeholder {
            color: $grey-05;
        }
    }

    .no-search-result {
        color: $brand-danger;
        font-size: 13px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .material-icons-outlined {
            font-size: 14px;
        }
    }

    .item {
        padding: 14px 20px;
        font-size: 16px;
        line-height: 1.38;

        > div {
            max-width: 280px;
            @include lines-1;
        }

        &.selected {
            color: $purple-primary;
            @include f-bold;

            .i-right {
                color: $purple-primary;
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid $grey-01;
        }
    }

    .i-right {
        font-size: 24px;
        margin: 0;
        color: $grey-04;
    }

    .grid-col {
        margin: 0 20px;
        grid-gap: 12px;
        grid-auto-rows: 48px;
        border-top: none;
        width: initial;

        .item {
            border-radius: 8px;
            border: 1px solid $grey-02;
            width: calc((100vw - 12px - 40px) / 2);
            @include center;

            &.selected {
                border: 1px solid $purple-primary;
            }

            .i-right {
                display: none;
            }
        }
    }
}
</style>
